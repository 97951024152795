import { DealerTradeValueType } from 'src/types/DealerTradeValueType';

export interface ClientDetailsCustomScript {
  codeName: string;
  description: string;
  sourceCode: string;
}

export enum VehicleSearchTypeEnum {
  VIN = 'VIN',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  PLATE = 'PLATE',
  DRILLDOWN = 'DRILLDOWN',
}

export interface ClientDetails {
  generate_lead: boolean; //"Optional"
  enable_recaptcha: boolean; //"Optional"
  allowUndecided: boolean; //1,
  show_next_vehicle: boolean;
  enable_gdl: boolean;
  apikey: string; //"demo"
  userName: string; //"demo"
  logo: string; //"https://trade.canadianblackbook.com/assets/icons/logo.png"
  client_ga: string; //"UA-884587-52"
  css: string; //{"logo-size": "auto"}

  firstNameField: 'Required' | 'Optional' | null; //"Required"
  lastNameField: 'Required' | 'Optional' | null; //"Required"
  addressField: 'Required' | 'Optional' | 'None' | null; //"Optional"
  emailField: 'Required' | 'Optional' | 'None' | null; //"Optional"
  phoneField: 'Required' | 'Optional' | 'None' | null; //"Optional"

  cityField: 'Required' | 'Optional' | 'None'; //"Optional"
  title: string; //"Demo"
  valueType: DealerTradeValueType; //"Estimated"
  vehicleSearchTypes: VehicleSearchTypeEnum[];
  country: string;
  restricted_next_vehicles: Array<any>; //limiting next vehicle selection to oem
  next_vehicle_start_year: string;
  phonevalidation_api?: string;
  token: string;
  custom_scripts: ClientDetailsCustomScript[];
  bac: string;
  session_id: string;
}

export function MapClientDetails(data: any): ClientDetails {
  if (!data) data = {};
  return {
    enable_recaptcha: data.enable_recaptcha,
    generate_lead: data.generate_lead,
    addressField: data.addressField,
    allowUndecided: !!data.allowUndecided,
    show_next_vehicle: data.show_next_vehicle,
    enable_gdl: data.enable_gdl,
    apikey: data.apikey,
    userName: data.userName,
    cityField: data.cityField,
    client_ga: data.client_ga,
    css: data.css,
    emailField: data.emailField,
    firstNameField: data.firstNameField,
    lastNameField: data.lastNameField,
    logo: data.logo,
    phoneField: data.phoneField,
    title: data.title,
    valueType: data.valueType,
    vehicleSearchTypes: data.vehicleSearchTypes || [],
    country: data.country,
    restricted_next_vehicles: data.restricted_next_vehicles || [],
    next_vehicle_start_year: data.next_vehicle_start_year || '',
    phonevalidation_api: data.phonevalidation_api,
    token: data.token || '',
    custom_scripts: (data.custom_scripts || []).map((el) => {
      return {
        codeName: el.codeName,
        description: el.description,
        sourceCode: el.sourceCode,
      };
    }),
    bac: data.bac,
    session_id: data.session_id,
  };
}
