import { UntypedFormGroup, Validators } from '@angular/forms';

export const Utils = {
  validateForm(form: UntypedFormGroup) {
    Object.keys(form.controls).forEach((key) => {
      form.controls[key].markAsTouched({ onlySelf: true });
      form.controls[key].markAsDirty({ onlySelf: true });
    });
    return form.valid;
  },
  /**
   * makes the field required if the predicate function returns true
   */
  requiredIfValidator(predicate) {
    return (formControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  },

  /** Create query parameters for the URL */
  kvpStripNulls(obj: any, removeEmpty = false) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] == null || (removeEmpty && obj[key] === '')) {
        delete obj[key];
      }
    });
    return obj;
  },

  injectScriptLink(location: 'head' | 'body', src: string): Promise<void> {
    const script = document.createElement('script');
    script.setAttribute('src', src);
    document[location].appendChild(script);

    return new Promise(function (resolve) {
      script.onload = function () {
        resolve(null);
      };
    });
  },

  injectScriptSource(location: 'head' | 'body', text: string): Promise<void> {
    const script = document.createElement('script');
    script.innerHTML = text;

    return new Promise(function (resolve, reject) {
      document[location].appendChild(script);

      script.onload = function () {
        resolve(null);
      };
      script.onerror = function (err) {
        reject(err);
      };

      script.onload(null);
    });
  },
  scrollToExpandedStep() {
    setTimeout(() => {
      const elem = <HTMLElement>window.document.querySelector('.step.expanded');
      if (!elem) return;
      elem.scrollIntoView({ behavior: 'smooth' });
    }, 15);
  },
  scrollToFirstError() {
    setTimeout(() => {
      const elem = <HTMLElement>(
        window.document.querySelector('.step.expanded .invalid,.step.expanded .ng-invalid:not(form)')
      );
      if (!elem) return;
      elem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      if (elem.focus) {
        elem.focus();
      }
    }, 15);
  },
  isSameObject(obj1: any, obj2: any) {
    if (obj1 === obj2) {
      return true; // Handles primitive values and references that are the same
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false; // Handles null, non-object types, or mismatched types
    }

    const keys1 = Object.keys(obj1 as object);
    const keys2 = Object.keys(obj2 as object);

    if (keys1.length !== keys2.length) {
      return false; // Different number of keys
    }

    for (const key of keys1) {
      if (!keys2.includes(key)) {
        return false; // Key mismatch
      }

      // Recursively compare values
      if (!Utils.isSameObject((obj1 as Record<string, unknown>)[key], (obj2 as Record<string, unknown>)[key])) {
        return false;
      }
    }

    return true;
  },

  copyObject(obj1: any) {
    if (obj1 == null) {
      return obj1;
    }
    return JSON.parse(JSON.stringify(obj1));
  },

  valOrEmptyString(obj: any) {
    return obj == null ? '' : obj;
  },
};
